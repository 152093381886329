import staff from "./staff-management.js";
import businessSetting from "./business-setting.js";
import customerManagement from "./customer-management.js";

const setting = [
    {
        name: "shippingWindow",
        path: "/basic/shipping-window",
        component: () => import(/* webpackChunkName: "basicDashboard" */ "@/views/basic/shipping-window.vue"),
        meta: { title: "发货窗口管理" },
    },
    {
        name: "pendingApproval",
        path: "/basic/pending-approval",
        component: () => import(/* webpackChunkName: "basicDashboard" */ "@/views/basic/pending-approval.vue"),
        meta: { title: "待办审批设置" },
    },
    {
        name: "dotInfo",
        path: "/basic/dot-info",
        component: () => import(/* webpackChunkName: "basicDashboard" */ "@/views/basic/dot-info.vue"),
        meta: { title: "网点资料表" },
    },
    {
        name: "ytoBranchSetting",
        path: "/basic/ytoBranchSetting",
        component: () => import(/* webpackChunkName: "basicDashboard" */ "@/views/basic/ytoBranchSetting.vue"),
        meta: { title: "同步窗口配置" },
    },
    {
        name: "ytoBillFilterSetting",
        path: "/basic/ytoBillFilterSetting",
        component: () => import(/* webpackChunkName: "basicDashboard" */ "@/views/basic/ytoBillFilterSetting.vue"),
        meta: { title: "同步分发配置" },
    },
    {
        name: "syncAllotTask",
        path: "/basic/syncAllotTask",
        component: () => import(/* webpackChunkName: "basicDashboard" */ "@/views/basic/syncAllotTask.vue"),
        meta: { title: "同步分发任务" },
    },
    {
        name: "lApproval",
        path: "/basic/l-approval",
        component: () => import(/* webpackChunkName: "basicDashboard" */ "@/views/basic/l-approval.vue"),
        meta: { title: "我审核的" },
    },
    {
        name: "lInitiate",
        path: "/basic/l-initiate",
        component: () => import(/* webpackChunkName: "basicDashboard" */ "@/views/basic/l-initiate.vue"),
        meta: { title: "我发起的" },
    },
    ...staff,
    ...businessSetting,
    ...customerManagement,
];

export default setting;
