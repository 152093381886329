export default [
    {
        name: "hq-config",
        path: "/hq/fee-config",
        component: () => import(/* webpackChunkName: "costQuotation" */ "@/views/hq/fee-config.vue"),
        meta: { title: "总部费用配置" },
    },
    {
        name: "hq-trade",
        path: "/hq/trade",
        component: () => import(/* webpackChunkName: "costQuotation" */ "@/views/hq/trade.vue"),
        meta: { title: "对总交易明细" },
    },
    {
        name: "hq-trade-summary",
        path: "/hq/trade-summary",
        component: () => import(/* webpackChunkName: "costQuotation" */ "@/views/hq/trade-summary.vue"),
        meta: { title: "对总交易汇总" },
    },
];
